<template>
  <div class="container container-narrow">
    <h1>CIDR Tools</h1>
    <form v-on:submit.prevent="submit">
      <div class="form-group">
        <label for="inp-ip1">IP Networks</label>
        <input
          type="text"
          name="ip1"
          id="inp-ip1"
          placeholder="0.0.0.0/0"
          class="form-control"
          v-model="ip1"
          @input="submit"
        />
      </div>
      <div class="form-group">
        <label for="inp-ip2">Subnetworks to exclude</label>
        <input
          type="text"
          name="ip2"
          id="inp-ip2"
          placeholder="192.168.1.1/24"
          class="form-control"
          v-model="ip2"
          @input="submit"
        />
      </div>
      <div class="form-group" v-if="error">
        <div class="alert alert-danger">{{ error }}</div>
      </div>
      <div class="form-group">
        <label for="inp-ipresult">Result</label>
        <textarea
          name="ipresult"
          id="inp-ipresult"
          placeholder=""
          class="form-control"
          :value="ipresult"
          readonly
          rows="10"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { exclude as cidrExclude } from "cidr-tools";
import { stringToArray } from "./utils/string-to-array.mjs";

export default {
  name: "app",
  data() {
    return {
      ip1: "0.0.0.0/0, ::/0",
      ip2: null,
      ipresult: null,
      error: null,
    };
  },
  methods: {
    submit() {
      if (!this.ip1 || this.ip1.length === 0) return;
      if (!this.ip2 || this.ip2.length === 0) return;

      const base = stringToArray(this.ip1);
      const exclude = stringToArray(this.ip2);

      try {
        this.ipresult = cidrExclude(base, exclude);
        this.error = null;
      } catch (e) {
        this.ipresult = null;
        this.error = e;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./styles/blueberry.scss";
</style>
